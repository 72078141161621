<template>
  <v-card class="elevation-1">
    <v-card-title>
      Other Deductions
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
    <template #[`item.employee_name`]="{ item }">
      {{`${item.lastname}, ${item.firstname} ${item.middlename}`}}
    </template>
    <template #[`item.total_amount`]="{ item }">
      {{Intl.NumberFormat().format(item.total_amount)}}
    </template>
    <template #[`item.deducted`]="{ item }">
      {{Intl.NumberFormat().format(item.deducted)}}
    </template>
    <template #[`item.balance`]="{ item }">
      {{Intl.NumberFormat().format(item.total_amount - item.deducted)}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import fnx from '@/functions/fn'

export default {
  props: ['formData'],

  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Employee Number', value: 'employee_number' },
      { text: 'Employee Name', value: 'employee_name' },
      { text: 'Particular', value: 'deductionTitle' },
      { text: 'Date Granted', value: 'date' },
      { text: 'Loan Amount', value: 'total_amount' },
      { text: 'Deducted', value: 'deducted' },
      { text: 'Balance', value: 'balance' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const uri = ref(`${process.env.VUE_APP_URI}/api/payroll/reports/deduction/others`)

    watch(options, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(() => props.formData, val => {
      items.value = []
      totalItems.value = 0

      if (val.groupBy === 'Department') {
        headers.value = [
          { text: 'Employee Number', value: 'employee_number' },
          { text: 'Employee Name', value: 'employee_name' },
          { text: 'Department', value: 'department' },
          { text: 'Particular', value: 'deductionTitle' },
          { text: 'Date Granted', value: 'date' },
          { text: 'Loan Amount', value: 'total_amount' },
          { text: 'Deducted', value: 'deducted' },
          { text: 'Balance', value: 'balance' },
        ]
      } else if (val.groupBy === 'Department') {
        headers.value = [
          { text: 'Employee Number', value: 'employee_number' },
          { text: 'Employee Name', value: 'employee_name' },
          { text: 'Outlet', value: 'outlet' },
          { text: 'Particular', value: 'deductionTitle' },
          { text: 'Date Granted', value: 'date' },
          { text: 'Loan Amount', value: 'total_amount' },
          { text: 'Deducted', value: 'deducted' },
          { text: 'Balance', value: 'balance' },
        ]
      }

      uri.value = `${process.env.VUE_APP_URI}/api/payroll/reports/deduction/others/${fnx.base64_encode(val)}`
      getData(uri.value, items, totalItems, loading, options, search)
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      fnx,
    }
  },
}
</script>
